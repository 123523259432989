<template>
  <div ref="col_lesson" class="main_box">
    <!-- 顶部 -->
    <headers ref="headers" :headname="headName"></headers>
    <!-- <p class="top_title">
      <span class="title_border"></span>
      <span class="title_text">收藏的课程</span>
    </p> -->
    <!-- 中间列表 -->
    <div class="main_cnt">
      <div v-show="lists && lists.length>0" class="content">

        <template v-for="(lesson,index) in lists">
          <div :key="index" class="lesson">
            <div class="thumb" @click="goLessonDetail(lesson)">
              <img :src="lesson.thumb" alt="">
            </div>
            <div class="lesson_info">
              <p class="name" @click="goLessonDetail(lesson)">{{ lesson.lesson_name }}</p>
              <span class="parts">共有{{ lesson.video_num }}节，其中免费试听{{ lesson.free_video_num }}</span>
              <div class="bott">
                <div class="price">
                  价格: <span class="price_r">￥{{ lesson.price }}</span>
                </div>
                <div class="menus">
                  <span v-if="lesson.buy_url" class="listen" @click="goBuy_(lesson)">立即购买</span>
                  <span v-else class="listen" @click="listenLesson(lesson)">查看课程</span>
                  <span class="cancel" @click="cancelCollect(lesson)">取消收藏</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-pagination
          v-show="count>params.limit"
          class="page"
          background
          :total="count"
          layout="prev, pager, next"
          :page-size="params.limit"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>

    </div>
    <!-- <div class="main_bot">
          底部栏
        </div> -->
    <!-- 无数据 -->
    <noData v-show="lists.length ===0" ref="noData" :msg="msg"></noData>
    <!-- <div v-show="lists.length ===0" class="noDataShow">
      <img src="@/assets/image/kong.png" alt="">
      <p>您还没有收藏的资料~</p>
    </div> -->
  </div>
</template>

<script>
import { cancelCollectCourse, getMyCollectLesson } from '@/api/center'
import { Message } from 'element-ui'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  components: { headers, noData },
  data() {
    return {
      headName: '收藏的课程',
      msg: '您还没有收藏的课程~',
      lists: [],
      count: 0,
      url: '', // 课程详情链接
      params: {
        page: 1,
        limit: 10
      }
    }
  },
  created() {
    this.getCollectLessons()
  },
  mounted() {
  },
  methods: {
    goLessonDetail(lesson) {
      window.open(lesson.url, '_blank')
    },
    // 收藏的课程
    getCollectLessons() {
      getMyCollectLesson(this.params).then(res => {
        const list = res.result.list
        this.lists = list
        this.count = res.result.count
      }).catch(err => {
        console.log(err)
      })
    },
    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getCollectLessons()
    },
    goBuy_(lesson) {
      window.open(lesson.buy_url, '_blank')
    },
    listenLesson(lesson) {
      window.open(lesson.url, '_blank')
    },
    // 取消收藏
    cancelCollect(lesson) {
      console.log('点击取消收藏')
      const _id = lesson._id
      const _idStr = _id.$oid
      console.log(_idStr)
      const param = { id: _idStr }
      cancelCollectCourse(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          // Message({ message: '已取消', type: 'success' })
          this.params.page = 1
          this.getCollectLessons()
        } else {
          const msg = res.msg
          Message({ message: msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .main_box {
        width: 965px;
        padding: 0 0 20px 0;
        min-height: 900px;
        position: relative;
        font-family: 'PingFangSC-Regular';
    }

    .top_title {
        height: 55px;
        width: 100%;
        line-height: 20px;
        font-size: 18px;
        position: relative;
        text-align: left;

        .title_text {
            display: inline-block;
            margin-left: 9px;
            margin-top: 25px;
            width: 120px;
            height: 30px;
            line-height: 30px;
            font-family: MicrosoftYaHei;
            font-size: 18px;
            color: #444444;
        }
    }

    .title_border {
        float: left;
        height: 20px;
        margin-top: 30px;
        margin-left: 20px;
        width: 4px;
        background: #f04e4f;
    }

    // .main_top {
    //   width: 100%;
    //   height: 55px;
    //   padding-top: 30px;
    //   padding-left: 23px;
    //   line-height: 20px;
    //   font-size: 18px;
    //   text-align: left;
    // }
    // .top_border {
    //   position: absolute;
    //   left: 10px;
    //   width: 4px;
    //   height: 20px;
    //   background: #f04e4f;
    // }
    .content {
        background-color: #fff;
        position: relative;
        // padding: 6px 10px 0;
        margin-left: 30px;
        min-height: 500px;
    }

    .lesson {
        height: 180px;
        padding: 30px 0;
        border-bottom: 1px solid #dddddd;
        text-align: left;
        display: flex;
    }

    .thumb {
        width: 157px;
        height: 120px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .lesson_info {
        float: left;
        display: flex;
        height: 120px;
        width: 600px;
        margin-left: 20px;
        flex-direction: column;
        justify-content: space-between;
        .name {
            cursor: pointer;
        }
        p {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0px;
            color: #444444;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .parts {
            line-height: 20px;
            font-size: 13px;
            color: #333333;
        }

        .bott {
            float: left;
            display: flex;
            line-height: 30px;

            .price {
                width: 100px;
                font-size: 13px;
                color: #333333;
            }

            .price_r {
                font-size: 13px;
                color: #ee2e2e;
            }

            .menus {
                margin-left: 15px;

                .listen {
                    display: inline-block;
                    width: 90px;
                    height: 30px;
                    background-color: #ee2e2e;
										border: 2px solid #ee2e2e;
										line-height: 26px;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #ffffff;
                    text-align: center;
                    cursor: pointer;
                }

                .cancel {
                    margin-left: 10px;
                    background-color: #fff;
                    display: inline-block;
                    width: 90px;
                    height: 30px;
                    border-radius: 4px;
                    border: 2px solid #ee2e2e;
										line-height: 26px;
                    font-size: 14px;
                    color: #ee2e2e;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }

    .page {
        padding: 20px 0;
        margin: 0 auto;
    }

    .noDataShow {
        position: relative;
        width: 180px;
        height: 180px;
        top: 400px;
        left: 340px;
        margin-top: -90px;

        p {
            text-align: center;
            font-size: 14px;
            color: #999999;
            margin-top: 30px;
        }

        img {
            display: block;
            margin: auto;
        }

        // margin-left: -180px;
    }

    .main_bot {
        background-color: gray;
    }
</style>
